<template>
  <div class="container-fluid">
    <CollectionNavTab />
    <div class="card p-2">
      <div class="row p-1 align-items-center">
        <div class="col-md-auto">
          <h2 class="roboto subtitle bold">Manage Collection</h2>
        </div>
        <div class="col d-flex align-items-center justify-content-end">
          <div class="text-nowrap mr-lg-2 bold">Brand :</div>
          <div class="flex-grow-1 flex-lg-grow-0">
            <b-form-select v-model.number="selectBrandId" >
              <b-form-select-option :value="0" disabled>Select Brand</b-form-select-option>
              <b-form-select-option :value="brand.id" v-for="brand in brands" :key="brand.id">{{ brand.name }}</b-form-select-option>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <b-table
            id="collection-list-table"
            :items="collectionList"
            :fields="tableFields"
            show-empty
            responsive
            hover
            thead-class="thead-light"
          >
            <template #cell(status)="{ value }">
              {{ getCollectionStatusText(value) }}
            </template>
            <template #cell(edit)="data">
              <fawesome-pro variant="far" icon="edit" class="p-1 text-black cursor-pointer hover-warning" :to="'/edit-collection/' + data.item.id" alt="Edit" title="Edit" />
              <fawesome-pro variant="far" icon="trash" class="p-1 text-black cursor-pointer hover-danger" v-b-modal.modal-delete-collection @click.native="selectedCollection = data.item" alt="Delete" title="Delete" />
              <fawesome-pro variant="far" icon="eye" class="p-1 text-black cursor-pointer hover-success" :to="'/detail-collection/' + data.item.id" alt="View" title="View" />
            </template>
            <template #empty>
              <div class="text-center">No data in this list</div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-delete-collection" hide-header centered
      @ok="deleteCollection(selectedCollection)"
      body-class="text-center"
      v-if="selectedCollection"
    >
        <b>{{ selectedCollection.name }}</b>
        <div>Are you sure you want to delete this collection?</div>
    </b-modal>
  </div>
</template>

<script>
import { toaster } from "@/_helpers";

const CollectionNavTab = () => import("@/components/collection/CollectionNavTab");

const initialData = () => ({
  brands: [],
  selectBrandId : 0,

  collectionList: [],
  selectedCollection: {}, 

  // For table
  tableFields: [
    { key: "name", label: "Name", tdClass: "align-middle"},
    { key: "description", label: "Description", tdClass: "align-middle"},
    { key: "status", tdClass: "align-middle" },
    { key: "edit", label: "Action", tdClass: "align-middle"}
  ],
})

export default {
  name: "ManageCollection",
  components: {
    CollectionNavTab,
  },
  data() {
    return initialData();
  },
  watch: {
    selectBrandId(id) {
      this.getCollectionList(id)
    }
  },
  mounted() {
    this.getBrands();
  },
  methods: {
    async getBrands() {
      try {
        const res = await this.$api.cms.getBrands()
        if (res.status === 200) {
          this.brands = res.data.data.map(el => ({ id : el.brand.id, name : el.brand.name }))
          this.selectBrandId = this.brands[0].id
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getCollectionList(brandId) {
      try {
        const res = await this.$api.collection.getCollectionByBrand(brandId)
        if (res.status === 200) this.collectionList = res.data.data
      } catch (error) {
        console.error(error);
      }
    },
    async deleteCollection(collection) {
      try {
        const res = await this.$api.collection.deleteCollection(collection.id)
        if (res.status === 200) {
          toaster.make(res.data.message, "success")
          this.collectionList.splice(this.collectionList.findIndex(el => el.id == collection.id), 1)
        }
      } catch (error) {
        console.error(error);
      }
    },
    getCollectionStatusText(val) {
      switch (val) {
        case "APR":
          return "APPROVED"          
        case "APL":
          return "APPLIED"          
        case "REJ":
          return "REJECTED"          
        default:
          return val;
      }
    },
  },
};
</script>